// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route } from '@redwoodjs/router';

const Routes = () => {
  return (
    <Router>
      <Route path="/pools/new" page={NewPoolPage} name="newPool" />
      <Route path="/pools/{id:Int}/edit" page={EditPoolPage} name="editPool" />
      <Route path="/pools/{id:Int}" page={PoolPage} name="pool" />
      <Route path="/pools" page={PoolsPage} name="pools" />
      <Route path="/rosters/new" page={NewRosterPage} name="newRoster" />
      <Route
        path="/rosters/{id:Int}/edit"
        page={EditRosterPage}
        name="editRoster"
      />
      <Route path="/rosters/{id:Int}" page={RosterPage} name="roster" />
      <Route path="/rosters" page={RostersPage} name="rosters" />
      <Route path="/" page={HomePage} name="home" />
      <Route path="/players/new" page={NewPlayerPage} name="newPlayer" />
      <Route path="/players/{id:Int}" page={PlayerPage} name="player" />
      <Route path="/players" page={PlayersPage} name="players" />
      <Route notfound page={NotFoundPage} />
    </Router>
  );
};

export default Routes;
